import cn from 'classnames'
import styled from '@emotion/styled'

import * as icons from 'assets/icons/groove/v2'
import themeVars from 'ui_theme/site/globals/site.variables'
// import ProgressCircle from '@groovehq/internal-design-system/lib/components/ProgressCircle/ProgressCircle'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { Negative } from '@groovehq/internal-design-system/lib/assets/icons'

export default styled(KeyValue)`
  color: rgb(79, 93, 106);
  padding: 1px 0;
  font-size: ${props => props.theme.spacing.base};
  font-weight: ${themeVars.graphikWeightMedium};
  &.clickable,
  .clickable {
    cursor: pointer;
  }
  .topline {
    display: flex;
    font-size: ${props => props.theme.spacing.base};
    font-weight: ${themeVars.graphikWeightMedium};
    .key {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      min-width: 0;
      flex-grow: 1;
      flex-shrink: 0;
      line-height: 22px;
      padding-right: ${props => props.theme.spacing['5']};
    }

    .pulse {
      height: 12px;
      align-self: center;
      margin-right: 7px;
    }

    .icon {
      height: 22px;
      margin-right: 6px;
      .fill {
        fill: ${props => props.theme.color.monochrome.mediumDark};
      }
      &.required {
        width: 18px;
        margin-left: -2px;
        margin-right: 3px;
        color: ${props => props.theme.color.primary.negative};
      }
    }
  }
  &:hover .topline .action {
    opacity: 1;
  }

  &:hover .topline .action,
  .groupedValue:hover .action {
    opacity: 1;
  }

  .action {
    cursor: pointer;
    border-radius: ${themeVars.defaultBorderRadius};
    padding: 4px 3px;
    width: 24px;
    height: 24px;
    text-align: center;
    opacity: 0;
    margin: -1px 0;
    flex-shrink: 0;
    flex-grow: 0;
    transition: opacity ${themeVars.defaultDuration} ease-in-out;
    &:hover {
      background: ${props => props.theme.color.monochrome.light};
    }
    .icon {
      height: ${props => props.theme.spacing['10']};
      padding: 0;
      margin: 0;
      color: ${props => props.theme.color.monochrome.mediumDark};
      &.small.icon {
        font-size: 12px;
      }
    }

    svg {
      height: 16px;
      width: 18px;
    }
  }

  .value.missing.editable:hover {
    color: var(--color-primary-brand);
  }

  .value {
    font-weight: ${themeVars.graphikWeightRegular};
    text-align: left;
    min-width: 0;
    padding-left: 28px;
    line-height: 22px;
    flex-grow: 1;
    padding-left: 16px;
    &.clickable {
      cursor: pointer;
    }

    &.singleline,
    .singleline {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.missing {
      color: #a6b4be;
      font-style: italic;
    }

    &.editable {
      cursor: pointer;
    }
  }

  &.singleline {
    .topline .key {
      flex-grow: 0;
    }
    .value {
      padding-left: 0;
    }
  }

  .groupedValues {
    padding-left: 28px;
    .groupedValue {
      display: flex;
      .value {
        flex-grow: 1;
        flex-shrink: 1;

        &.singleline {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
  .addMissingValue {
    color: #a6b4be;
    cursor: pointer;
  }
  &:hover .addMissingValue {
    color: ${props => props.theme.color.primary.brand};
  }
  &:hover .groupedValues .groupedValue .editable.missing .addMissingValue {
    color: #a6b4be;
  }
  .groupedValues .groupedValue:hover .editable.missing {
    color: ${props => props.theme.color.primary.brand};
  }
  &.singleline {
    .topline .key {
      flex-grow: 0;
    }
    .value {
      padding-left: 0;
    }
  }

  &.showActions {
    .action {
      display: block;
    }
  }
  &.hideActions {
    .action,
    .action:hover {
      display: none;
    }
  }
  &&.grouped .key {
    flex-grow: 1;
  }
`

const KeyIcon = ({ icon, required, value }) => {
  if (required && !value) {
    return (
      <Tooltip title="Required field" strategy="fixed" position="top">
        <div className="grui flex">
          {/*  Move aggressive option
          <ProgressCircle
            className="pulse"
            color="color.primary.negative"
            rippleColor="color.primary.negative"
          />
          */}
          <Negative className="icon required" />
        </div>
      </Tooltip>
    )
  }

  const KeyIconComponent = typeof icon === 'string' ? icons[icon] : icon
  return KeyIconComponent ? <KeyIconComponent className="icon" /> : null
}

function KeyValue({
  actions,
  children,
  className,
  icon,
  grouped = false,
  onClick,
  onlyTopLineClickable = false,
  missingValue = 'Unknown',
  showActions = null,
  singleline = true,
  title,
  value,
  required,
}) {
  let valueElement
  if (value || value === 0) {
    valueElement = <div className={cn('value', { singleline })}>{value}</div>
  } else if (missingValue) {
    valueElement = (
      <div className={cn('value missing', { singleline })}>{missingValue}</div>
    )
  }

  return (
    <div
      className={cn('KeyValue', className, {
        clickable: !!onClick && !onlyTopLineClickable,
        singleline,
        showActions: showActions === true,
        hideActions: showActions === false,
        grouped,
      })}
      onClick={!onlyTopLineClickable && onClick ? onClick : undefined}
    >
      <div
        className={cn('topline', {
          clickable: !!onClick && onlyTopLineClickable,
        })}
        onClick={!!onlyTopLineClickable && onClick ? onClick : undefined}
      >
        <KeyIcon required={required} value={value} icon={icon} />
        <div className="key">{title}</div>
        {!grouped && singleline && valueElement}
        {!grouped && children}
        {actions}
      </div>
      {!grouped && !singleline && valueElement}
      {grouped && <div className="groupedValues">{children}</div>}
    </div>
  )
}
