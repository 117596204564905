import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Dropdown, {
  divider,
} from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_FOLDERS_UPDATE,
  DRAWER_TYPE_FOLDERS_DELETE,
  DRAWER_TYPE_FOLDERS_OVERVIEW,
} from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { buildId } from 'util/globalId'
import { selectCurrentFolderById } from 'ducks/folders/selectors/folders'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'

const MoreFolderOptions = ({ id }) => {
  const { openDrawer: openCreateEditDrawer } = useDrawer(
    DRAWER_TYPE_FOLDERS_UPDATE
  )
  const { drawerId: deleteDrawerId, openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_FOLDERS_DELETE
  )
  const { openDrawer: openOverviewDrawer } = useDrawer(
    DRAWER_TYPE_FOLDERS_OVERVIEW
  )
  const folderId = useMemo(() => buildId('Folder', id), [id])
  const { isDefault = true } =
    useSelector(state => selectCurrentFolderById(state, folderId)) || {}

  const handleEdit = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      openCreateEditDrawer(folderId)
    },
    [openCreateEditDrawer, folderId]
  )

  const handleDelete = useCallback(
    e => {
      e.stopPropagation()
      openDeleteDrawer(folderId, {
        query: {
          ...buildDrawerQueryParam(deleteDrawerId, 'drawerDeleteMode', 'ids'),
        },
      })
    },
    [openDeleteDrawer, deleteDrawerId, folderId]
  )

  const handleManageAll = useCallback(
    e => {
      e.stopPropagation()
      openOverviewDrawer(folderId)
    },
    [openOverviewDrawer, folderId]
  )

  const stopMenuRedirect = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }, [])

  return (
    <Menu.Item.MoreOptions
      onClick={stopMenuRedirect}
      overlay={
        <Dropdown.Menu>
          <Dropdown.Menu.Item onClick={handleEdit} itemKey="edit-folder">
            Edit {app.t('folder')}
          </Dropdown.Menu.Item>
          <Tooltip
            title={`This is a default ${app.t('folder')} and cannot be deleted`}
            position="right"
            disabled={!isDefault}
          >
            <div>
              <Dropdown.Menu.Item
                onClick={handleDelete}
                itemKey="remove-folder"
                disabled={isDefault}
                isWarning
              >
                Remove {app.t('folder')}
              </Dropdown.Menu.Item>
            </div>
          </Tooltip>
          {divider}
          <Dropdown.Menu.Item onClick={handleManageAll} itemKey="edit-folders">
            Manage {app.t('folders')}
          </Dropdown.Menu.Item>
        </Dropdown.Menu>
      }
      menuItemIconHidden
    />
  )
}

export default MoreFolderOptions
