import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useLexicalTextEntity } from '@lexical/react/useLexicalTextEntity'
import { queryIdToQuery } from 'ducks/searches/utils/query'
import { getFilterMatchForQuery } from 'util/search/filter'
import { selectCurrentFoldersById } from 'ducks/folders/selectors/folders'
import { selectFilterRegex } from 'selectors/search/searchFilters'
import { FilterNode, $createFilterNode } from './FilterNode'

const FilterPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const foldersById = useSelector(selectCurrentFoldersById)
  const filterRegex = useSelector(selectFilterRegex)

  useEffect(
    () => {
      if (!editor.hasNodes([FilterNode])) {
        throw new Error('FilterPlugin: FilterNode not registered on editor')
      }
    },
    [editor]
  )

  // If we have a folder search e.g. `folder:1464931230` we show the name of the
  // folder instead of the id.
  useEffect(
    () => {
      return editor.registerNodeTransform(FilterNode, filterNode => {
        const text = filterNode.getTextContent()
        const query = queryIdToQuery(text)
        const folderId = query?.folderId
        if (!folderId) return
        const name = foldersById[folderId]?.name
        if (!name) return
        filterNode.setTextContent(`folder:"${name}"`)
        filterNode.select()
      })
    },
    [editor, foldersById]
  )

  const createFilterNode = useCallback((textNode, valueId) => {
    return $createFilterNode(textNode.getTextContent(), valueId)
  }, [])

  const getFilterMatch = useCallback(
    text => {
      const matchArr = getFilterMatchForQuery(text, filterRegex)
      if (matchArr === null) {
        return null
      }
      return {
        end: matchArr.index + matchArr[0].length,
        start: matchArr.index,
      }
    },
    [filterRegex]
  )

  useLexicalTextEntity(getFilterMatch, FilterNode, createFilterNode)

  return null
}

export default FilterPlugin
