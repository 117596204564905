/* eslint-disable no-underscore-dangle */
// Kevin R (2019-07-23):  This file has been heavily modified by glenn in his react upgrade PR. I dont want to lint this file and cause hundreds of merge conflicts
//                        when he's already done all that work. For now im just disabling the linter
import React from 'react'
import cn from 'classnames'
import { mapObject } from 'util/objects'
import { isFunction, runOnNextTick } from 'util/functions'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import ScrollerContext from './Context'
import styles from './styles.css'

class Scroller extends React.Component {
  static defaultProps = {
    loadMore: undefined,
    loader: undefined,
    scrollTarget: undefined,
    onScroll: undefined,
    topThreshold: 0,
    bottomThreshold: 0,
    onReachTop: undefined,
    onLeaveTop: undefined,
    onReachBottom: undefined,
    onLeaveBottom: undefined,
    focusedElement: undefined,
    onMouseMove: undefined,
    onMouseOut: undefined,
  }

  constructor(props, context) {
    super(props, context)
    this.boundHandleScroll = this.handleScroll.bind(this)
    this.scrollerAPI = null
    if (this.props.scrollTarget === window) {
      this.setTarget(document)
    } else if (this.props.scrollTarget) {
      // NOTE (jscheel): This value will be overwritten by the render method
      // when it sets up the appropriate ref.
      this.setTarget(this.props.scrollTarget)
    }
  }

  state = { hidden: false }

  // NOTE (jscheel) These don't need to be state-based.
  // eslint-disable-next-line react/sort-comp
  _ticking = false
  _tickingLocks = 0
  _listening = false
  _target = undefined
  _root = undefined
  _withinTopArea = false
  _withinBottomArea = false
  _apiCallTimeout = null
  _unmounting = false

  // NOTE (jscheel): The scroller API is dependent on the scrolling container
  // actually existing. Instead of asking the developer of the calling component
  // to know about this restriction, we instead bind all of the api calls and
  // defer them if the target is not ready yet.
  _bindQueueableApiCall = originalMethod => {
    const _this = this
    return function boundApiCall() {
      if (_this._unmounting) {
        return
      }
      // eslint-disable-next-line prefer-rest-params
      const args = arguments
      if (!_this._target) {
        if (!_this.state.hidden) {
          _this.setState({ hidden: true })
        }
        _this._apiCallTimeout = runOnNextTick(() => {
          originalMethod(...args)
          if (_this.state.hidden) {
            _this.setState({ hidden: false })
          }
        })
        return
      }
      if (_this.state.hidden) {
        _this.setState({ hidden: false })
      }
      originalMethod(...args)
    }
  }

  getScrollerAPI = () => {
    if (this.scrollerAPI) return this.scrollerAPI
    // NOTE (jscheel): Any methods that modify the scroll position need to be
    // defered until the next tick, and the scroller needs to hide the content
    // if the target reference is not available yet. To do this, these api
    // methods are bound via _bindQueueableApiCall, which is defined above.
    if (!this.scrollerAPI) {
      this.scrollerAPI = {
        refresh: () => this.refresh(),
        getScrollDimensions: () => this.computeScrollDimensions(),
        getElement: () => this._target,
        ...mapObject(this._bindQueueableApiCall)({
          scrollElementIntoView: el => this.scrollElementIntoView(el),
          scrollElementIntoViewIfNeeded: el =>
            this.scrollElementIntoViewIfNeeded(el),
          scrollToX: x => this.scrollToX(x),
          scrollToY: y => this.scrollToY(y),
          scrollToBottom: () => this.scrollToBottom(),
        }),
      }
    }
    return this.scrollerAPI
  }

  setTarget(target) {
    this._target = target
  }

  refresh() {
    this.forceUpdate()
  }

  // Adapted from https://gist.github.com/KilianSSL/774297b76378566588f02538631c3137
  scrollElementIntoView(el) {
    const container = this._target
    if (el && container) {
      this._preventComputeTrackbarVisibility = true
      const containerComputedStyle = window.getComputedStyle(container, null)
      const containerBorderTopWidth = parseInt(
        containerComputedStyle.getPropertyValue('border-top-width'),
        10
      )
      const containerBorderLeftWidth = parseInt(
        containerComputedStyle.getPropertyValue('border-left-width'),
        10
      )
      const overTop = el.offsetTop - container.offsetTop < container.scrollTop
      const overBottom =
        el.offsetTop -
          container.offsetTop +
          el.clientHeight -
          containerBorderTopWidth >
        container.scrollTop + container.clientHeight
      const overLeft =
        el.offsetLeft - container.offsetLeft < container.scrollLeft
      const overRight =
        el.offsetLeft -
          container.offsetLeft +
          el.clientWidth -
          containerBorderLeftWidth >
        container.scrollLeft + container.clientWidth

      if (overTop || overBottom) {
        container.scrollTop = el.offsetTop
      }

      if (overLeft || overRight) {
        container.scrollLeft = el.offsetLeft
      }

      return true
    }

    return false
  }

  // Adapted from https://gist.github.com/KilianSSL/774297b76378566588f02538631c3137
  scrollElementIntoViewIfNeeded(el, centerIfNeeded = true) {
    const container = this._target
    if (el && container) {
      this._preventComputeTrackbarVisibility = true
      const containerComputedStyle = window.getComputedStyle(container, null)
      const containerBorderTopWidth = parseInt(
        containerComputedStyle.getPropertyValue('border-top-width'),
        10
      )
      const containerBorderLeftWidth = parseInt(
        containerComputedStyle.getPropertyValue('border-left-width'),
        10
      )
      const overTop = el.offsetTop - container.offsetTop < container.scrollTop
      const overBottom =
        el.offsetTop -
          container.offsetTop +
          el.clientHeight -
          containerBorderTopWidth >
        container.scrollTop + container.clientHeight
      const overLeft =
        el.offsetLeft - container.offsetLeft < container.scrollLeft
      const overRight =
        el.offsetLeft -
          container.offsetLeft +
          el.clientWidth -
          containerBorderLeftWidth >
        container.scrollLeft + container.clientWidth

      if ((overTop || overBottom) && centerIfNeeded) {
        container.scrollTop =
          el.offsetTop -
          container.offsetTop -
          container.clientHeight / 2 -
          containerBorderTopWidth +
          el.clientHeight / 2
      }

      if ((overLeft || overRight) && centerIfNeeded) {
        container.scrollLeft =
          el.offsetLeft -
          container.offsetLeft -
          container.clientWidth / 2 -
          containerBorderLeftWidth +
          el.clientWidth / 2
      }

      if ((overTop || overBottom) && !centerIfNeeded) {
        container.scrollTop = el.offsetTop
      }

      if ((overLeft || overRight) && !centerIfNeeded) {
        container.scrollLeft = el.offsetLeft
      }

      return true
    }

    return false
  }

  scrollToX(x) {
    if (this._target === document) {
      window.scrollTo(x, window.scrollY)
    } else {
      this._target.scrollLeft = x
    }
  }

  scrollToY(y) {
    if (this._target === document) {
      window.scrollTo(window.scrollX, y)
    } else {
      this._target.scrollTop = y
    }
  }

  scrollToBottom() {
    if (this._target === document) {
      const y =
        this._target.documentElement.scrollHeight ||
        this._target.body.scrollHeight
      window.scrollTo(window.scrollX, y)
    } else {
      const y = this._target.scrollHeight
      this._target.scrollTop = y
    }
  }

  shouldListenToScroll(targetProps = this.props) {
    const {
      onReachTop,
      onLeaveTop,
      onReachBottom,
      onLeaveBottom,
      loadMore,
      onScroll,
    } = targetProps
    return (
      !!onReachTop ||
      !!onLeaveTop ||
      !!onReachBottom ||
      !!onLeaveBottom ||
      !!loadMore ||
      !!onScroll
    )
  }

  scrollToFocusedElement() {
    const el = this.props.focusedElement
    if (el) return this.scrollElementIntoViewIfNeeded(el)
    return false
  }

  componentDidMount() {
    // delay to prevent cascading render
    setTimeout(this._componentDidMount, 200)
  }

  _componentDidMount = () => {
    const { onScrollInit } = this.props
    const dimensions = this.computeScrollDimensions()
    if (dimensions) {
      this._withinTopArea = dimensions.withinTopArea
      this._withinBottomArea = dimensions.withinBottomArea
    }
    if (onScrollInit) {
      onScrollInit({
        scrollerAPI: this.getScrollerAPI(),
      })
    }
    if (this.shouldListenToScroll()) {
      this.attachScrollHandler()
    }
    this.scrollToFocusedElement()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.target && nextProps.target !== this._target) {
      this.removeScrollHandler()
    }
    const shouldListen = this.shouldListenToScroll(nextProps)
    if (shouldListen && !this._listening) {
      this.attachScrollHandler()
    } else if (!shouldListen && this._listening) {
      this.removeScrollHandler()
    }
    if (nextProps.scrollTarget === window) {
      this.setTarget(document)
    } else if (this.props.scrollTarget) {
      // NOTE (jscheel): This value will be overwritten by the render method
      // when it sets up the appropriate ref.
      this.setTarget(this.props.scrollTarget)
    }
  }

  componentDidUpdate() {
    // delay to prevent cascading render
    setTimeout(this._componentDidUpdate, 100)
  }

  componentWillUnmount() {
    this.removeScrollHandler()
    this._unmounting = true
    clearTimeout(this._apiCallTimeout)
  }

  _componentDidUpdate = () => {
    this.scrollToFocusedElement()
  }

  attachScrollHandler() {
    this._target.addEventListener('scroll', this.boundHandleScroll, true)
    window.addEventListener('resize', this.boundHandleScroll, true)
    this._listening = true
  }

  removeScrollHandler() {
    this._target.removeEventListener('scroll', this.boundHandleScroll, true)
    window.removeEventListener('resize', this.boundHandleScroll, true)
    this._listening = false
  }

  computeScrollDimensions() {
    // HACK (jscheel): Because the target usually doesn't get set until the
    // render happens, we have to make sure the target exists. I need to come
    // up with a better way to do this.
    if (!this._target) {
      return undefined
    }
    const { topThreshold, bottomThreshold } = this.props
    const scrollTop =
      (this._target.scrollTop === undefined
        ? window.pageYOffset || document.body.scrollTop
        : this._target.scrollTop) || 0
    const contentHeight =
      this._target === document
        ? this._target.documentElement.scrollHeight ||
          this._target.body.scrollHeight
        : this._target.scrollHeight
    const containerHeight =
      this._target === document
        ? this._target.documentElement.clientHeight ||
          this._target.body.clientHeight
        : this._target.clientHeight
    const withinTopArea = scrollTop <= parseInt(topThreshold, 10)
    const withinBottomArea =
      scrollTop + containerHeight >=
      contentHeight - parseInt(bottomThreshold, 10)

    return {
      scrollTop,
      contentHeight,
      containerHeight,
      withinTopArea,
      withinBottomArea,
    }
  }

  incrementTick() {
    this._tickingLocks += 1
  }

  decrementTick() {
    this._tickingLocks = Math.max(this._tickingLocks - 1, 0)
  }

  isTicking() {
    return this._tickingLocks > 0
  }

  handleScroll(e) {
    const { onReachTop, onLeaveTop, onReachBottom, onLeaveBottom } = this.props
    if (!this.isTicking()) {
      window.requestAnimationFrame(() => {
        const dimensions = this.computeScrollDimensions()
        const eventObject = {
          ...dimensions,
          originalEvent: e,
        }
        const api = this.getScrollerAPI()
        // NOTE (jscheel): We don't do instanceof checks on the callbacks as it
        // is fairly slow and offers no tangible benefit.
        this.onScroll(eventObject, api)
        if (dimensions.withinTopArea && !this._withinTopArea) {
          this._withinTopArea = true
          if (onReachTop) {
            this.incrementTick()
            window.requestAnimationFrame(() => {
              onReachTop(eventObject, api)
              this.decrementTick()
            })
          }
        } else if (!dimensions.withinTopArea && this._withinTopArea) {
          this._withinTopArea = false
          if (onLeaveTop) {
            this.incrementTick()
            window.requestAnimationFrame(() => {
              onLeaveTop(eventObject, api)
              this.decrementTick()
            })
          }
        }
        if (dimensions.withinBottomArea && !this._withinBottomArea) {
          this._withinBottomArea = true
          if (onReachBottom) {
            this.incrementTick()
            window.requestAnimationFrame(() => {
              onReachBottom(eventObject, api)
              this.decrementTick()
            })
          }
        } else if (!dimensions.withinBottomArea && this._withinBottomArea) {
          this._withinBottomArea = false
          if (onLeaveBottom) {
            this.incrementTick()
            window.requestAnimationFrame(() => {
              onLeaveBottom(eventObject, api)
              this.decrementTick()
            })
          }
        }
        this.decrementTick()
      })
    }
  }

  // call hasMore prop depending on if it's a function or a variable
  hasMore() {
    const { hasMore: inputHasMore } = this.props

    if (isNullOrUndefined(inputHasMore)) return false

    if (isFunction(inputHasMore)) {
      return inputHasMore()
    }

    return inputHasMore
  }

  onScroll = evt => {
    const { scrollTop, contentHeight, containerHeight } = evt
    const {
      children,
      loadMoreThreshold = 100,
      loadingMore,
      loadMore,
      onScroll,
    } = this.props

    if (loadMore) {
      const itemCount = children.size ? children.size : children.length

      if (itemCount) {
        const withinThreshold =
          scrollTop + containerHeight >= contentHeight - loadMoreThreshold

        if (withinThreshold && this.hasMore() && !loadingMore) loadMore()
      }
    }

    if (onScroll) onScroll(evt)

    return true
  }

  saveRef = c => {
    if (c && !this._target) this.setTarget(c)
    if (this.props.innerRef) {
      this.props.innerRef.current = c
    }
  }

  render() {
    const {
      children,
      loader,
      endIndicator,
      className,
      style,
      onMouseMove,
      onMouseOut,
      footer,
    } = this.props

    return (
      <ScrollerContext.Provider value={{ getScrollerAPI: this.getScrollerAPI }}>
        <div
          className={cn(styles.scrollerRoot, className, 'printable', {
            [styles.hidden]: this.state.hidden,
          })}
          style={style}
          ref={this.saveRef}
          onMouseMove={onMouseMove}
          onMouseOut={onMouseOut}
        >
          {children}
          {this.hasMore() && loader}
          {!this.hasMore() && endIndicator}
          {!!footer && footer}
        </div>
      </ScrollerContext.Provider>
    )
  }
}

export default Scroller
