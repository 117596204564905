import { useSelector } from 'react-redux'
import { selectIsOnSearchPage } from 'selectors/location'
import FilterBadges from './FilterBadges'

// Filter badges is pulling in a bunch of expensive selectors. We'll have this super chearp
// entry component to check if we are on a search page before loading up the rest
export default function FilterBadgesEntry() {
  const isSearchPage = useSelector(selectIsOnSearchPage)
  if (!isSearchPage) return null

  return <FilterBadges />
}
