import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCollectionById,
  selectItemAndGroupIds,
  selectOpenCollectionIds,
} from 'ducks/folders/selectors/collections'
import { doOpenOneCollection } from 'ducks/folders/operations/collections'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import Link from 'redux-first-router-link'
import Negative from '@groovehq/internal-design-system/lib/assets/icons/Negative'
import SearchRawCount from '../SearchRawCount'
import AvailabilityStatus from '../AvailabilityStatus'
import Item from '../Item'
import Group from '../Group'
import { styles } from './styles'

const FolderCollection = props => {
  const { collectionId, hideName = false, onClick, compactView } = props
  const dispatch = useDispatch()

  const { id, name, color, queryId, linkTo, warning } = useSelector(state =>
    selectCollectionById(state, collectionId)
  )

  const openCollectionIds = useSelector(selectOpenCollectionIds)
  const open = compactView || openCollectionIds.includes(id)

  const itemAndGroupIds = useSelector(state =>
    selectItemAndGroupIds(state, collectionId)
  )

  const handleClick = useCallback(
    evt => {
      if (onClick) onClick(collectionId, evt)
      dispatch(doOpenOneCollection({ collectionId, event: evt }))
    },
    [dispatch, onClick, collectionId]
  )

  const subMenu = open && (
    <>
      {itemAndGroupIds.map(({ id: listId, type }) => {
        if (type === 'item') {
          return <Item key={listId} itemId={listId} isSubMenuItem />
        }
        if (type === 'group') {
          return <Group key={listId} groupId={listId} />
        }
        return null
      })}
    </>
  )

  if (compactView) {
    return subMenu
  }

  return (
    <Menu.UnmanagedItem
      key={collectionId}
      as={Link}
      to={linkTo}
      onClick={handleClick}
      open={open}
      count={!warning && <SearchRawCount queryId={queryId} />}
      dotColor={color}
      css={styles.base}
      // The negative margin is for offsetting the icon's whitespace,
      // so it can align well with other inboxes's count numbers
      extraIcon={warning && <Negative className="grui -mr-4" />}
      extraIconType={warning ? 'warning' : null}
      subMenu={subMenu}
    >
      {!hideName && (
        <>
          {name}
          <AvailabilityStatus widgetId={collectionId.replace(/wid_/, '')} />
        </>
      )}
    </Menu.UnmanagedItem>
  )
}

export default FolderCollection
