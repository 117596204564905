import React, { useCallback, useState, useEffect } from 'react'

import { IconButton } from 'shared/ui'
import TagsDropdown from 'subapps/settings/components/TagsDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { doUpdateTags } from 'ducks/tickets/actions/doUpdateTags'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { areArraysEqual } from 'util/arrays'
import { emptyObj } from 'util/objects'
import { TAG_DROPDOWN_VARIANTS } from 'subapps/settings/components/TagsDropdown/constants'

const TagsButton = React.memo(
  ({ conversationId, tagsOpen, onTagIconClick, onTagDropdownClose }) => {
    const dispatch = useDispatch()
    const { tags } = useSelector(
      state => selectCurrentConversationById(state, conversationId) || emptyObj
    )
    const [selectedIds, setSelectedIds] = useState(tags)
    const handleOnVisibleChange = useCallback(
      visible => {
        if (visible === false) {
          onTagDropdownClose()
        }
      },
      [onTagDropdownClose]
    )
    const handleOnSetValue = useCallback(
      newTags => {
        dispatch(doUpdateTags(conversationId, newTags))
        onTagDropdownClose()
      },
      [dispatch, conversationId, onTagDropdownClose]
    )
    useEffect(
      () => {
        // When the tags dropdown gets closed without saving
        // the changes (clicks cancel, or in open space) we need
        // to reset the current selection
        if (!tagsOpen && !areArraysEqual(selectedIds, tags)) {
          setSelectedIds(tags)
        }
      },
      [tagsOpen, selectedIds, tags]
    )

    return (
      <div className="tags">
        <TagsDropdown
          fieldType="ids"
          onSelectedIdsChange={setSelectedIds}
          selectedIds={selectedIds}
          value={selectedIds}
          setValue={handleOnSetValue}
          onVisibleChange={handleOnVisibleChange}
          visible={tagsOpen}
          offset={9}
          variant={TAG_DROPDOWN_VARIANTS.INBOX}
        >
          <IconButton
            tooltip="Tag [t]"
            name="tag"
            onClick={onTagIconClick}
            size="medium"
            svg
            className="menu-button"
          />
        </TagsDropdown>
      </div>
    )
  }
)

export default TagsButton
