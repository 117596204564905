import React, { useCallback } from 'react'
import cn from 'classnames'

import { IconButton, SUI } from 'shared/ui'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsPinnedByQueryId } from 'ducks/pinnedSearch/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { doPinSearch, doUnpinSearch } from 'ducks/pinnedSearch/actions'
import { styles } from './styles'

const PinIcon = ({ disabled = false }) => {
  const dispatch = useDispatch()
  const queryId = useSelector(selectCurrentQueryId)
  const isPinned = useSelector(state => selectIsPinnedByQueryId(state, queryId))
  const handleOnClick = useCallback(
    () => {
      if (isPinned) {
        return dispatch(doUnpinSearch(queryId))
      }
      return dispatch(doPinSearch(queryId))
    },
    [dispatch, isPinned, queryId]
  )

  return (
    <SUI>
      <IconButton
        name="pin"
        size="small"
        tooltip={isPinned ? 'Unpin search' : 'Pin search'}
        iconSize="20"
        onClick={!disabled && handleOnClick}
        css={styles.pinStyle}
        className={cn({ pinned: isPinned })}
        svg
      />
    </SUI>
  )
}

export default PinIcon
